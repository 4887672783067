<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class=""
      @submit.prevent="handleSubmit(saveGuide)"
      @reset.prevent="resetForm"
    >
      <b-card class="invoice-preview-card">
        <b-card-title>
          CREAR GUÍA
        </b-card-title>

        <!-- Header -->
        <b-card-body class="invoice-padding pb-0">

          <div class="d-flex justify-content-end flex-md-row flex-column invoice-spacing mt-0">

            <!-- Header: Right Content -->
            <div class="invoice-number-date mt-md-0 mt-2">
              <div class="d-flex align-items-center">
                <!--                <span class="title">-->
                <!--                 N°GUIA :-->
                <!--                </span>-->

                <!-- Expiration date -->
                <validation-provider
                  #default="validationContext"
                  name="N°GUIA"
                  rules="required"
                >
                  <b-form-group
                    label-for="nro-guide"
                    label-cols="6"
                    label-cols-lg="3"
                    label="N°GUIA"
                  >

                    <b-form-input
                      id="nro-guide"
                      v-model="guideObject.nroGuide"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </div>
            </div>
          </div>
        </b-card-body>
        <b-row align-h="between">
          <b-col
            cols="12"
            md="4"
          >
            <!-- Date Issue -->
            <b-form-group
              label="FECHA DE EMISION"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="guideData.dateIssue"
                disabled
                trim
              />
            </b-form-group>

            <!-- Business Name -->
            <b-form-group
              label="RAZON SOCIAL"
              label-for="product_code"
            >
              <b-form-input
                id="product_code"
                :value="guideData.businessName"
                disabled
                trim
              />
            </b-form-group>

            <!-- RUC -->
            <b-form-group
              label="R.U.C."
              label-for="product_code"
            >
              <b-form-input
                id="product_code"
                :value="guideData.ruc"
                disabled
                trim
              />
            </b-form-group>

            <!-- ADDRESS -->
            <b-form-group
              label="DIRECCIÓN"
              label-for="product_code"
            >
              <b-form-textarea
                disabled
                :value="guideData.address"
                rows="2"
                class="mb-2 mb-lg-0"
              />
            </b-form-group>

            <!-- POINT START -->
            <b-form-group
              label="PUNTO DE PARTIDA"
              label-for="product_code"
            >
              <b-form-input
                id="product_code"
                :value="warehouse.address"
                disabled
                trim
              />
            </b-form-group>

            <!-- ARRIVAL POINT -->
            <validation-provider
              #default="validationContext"
              name="PUNTO DE LLEGADA"
              rules="required"
            >
              <b-form-group
                label="PUNTO DE LLEGADA"
                label-for="arrival-point"
              >
                <b-form-input
                  id="arrival-point"
                  v-model="guideObject.arrivalPoint"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- SALE -->
            <b-form-group
              label="VENDEDOR"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="guideData.seller"
                disabled
                trim
              />
            </b-form-group>

            <!-- INVOICE -->
            <validation-provider
              #default="validationContext"
              name="FACTURA"
              rules="required"
            >
              <b-form-group
                label="FACTURA"
                label-for="invoice-data"
              >
                <b-form-input
                  id="invoice-data"
                  v-model="guideObject.invoice"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- REFERENCE -->
            <b-form-group
              label="REFERENCIA"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="guideData.quotationCode"
                disabled
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-card-title>
          DATOS TRANSPORTE
        </b-card-title>
        <b-row align-h="between">
          <b-col
            cols="12"
            md="4"
          >
            <!-- BUSINESS TRANSPORT -->
            <b-form-group
              label="RAZON TRANSP"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="warehouse.businessName"
                disabled
                trim
              />
            </b-form-group>

            <!-- ADDRESS TRANSPORT -->
            <b-form-group
              label="DOMICILIO TRANSP"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="warehouse.address"
                disabled
                trim
              />
            </b-form-group>

            <!-- NAME DRIVER -->
            <validation-provider
              #default="validationContext"
              name="NOMBRE DEL CONDUCTOR"
              rules="required"
            >
              <b-form-group
                label="NOMBRE DEL CONDUCTOR"
                label-for="driver-option"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="guideObject.driverId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="driverOptions"
                  label="name"
                  input-id="driver-option"
                  :reduce="option => option.id"
                  :clearable="false"
                  @input="val => updateItemForm(val,'driver')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- LICENSE -->
            <b-form-group
              label="N°LICENCIA CONDUCIR"
              label-for="product_code"
            >
              <b-form-input
                id="product_code"
                :value="guideData.nroLicense"
                disabled
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <!-- RUC -->
            <b-form-group
              label="RUC"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                v-model="warehouse.ruc"
                disabled
                trim
              />
            </b-form-group>

            <!-- VEHICLE -->
            <validation-provider
              #default="validationContext"
              name="PLACA VEHICULO"
              rules="required"
            >
              <b-form-group
                label="PLACA VEHICULO"
                label-for="vehicle-option"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="guideObject.vehicleId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="vehicleOptions"
                  label="registrationNumberComplete"
                  input-id="vehicle-option"
                  :reduce="option => option.id"
                  :clearable="false"
                  @input="val => updateItemForm(val,'vehicle')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- BRANCH VEHICLE -->
            <b-form-group
              label="MARCA VEHICULO"
              label-for="product_name"
            >
              <b-form-input
                id="product_name"
                :value="guideData.brand"
                disabled
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-card-title>
          Traslado
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- REASON FOR TRANSFER -->
            <validation-provider
              #default="validationContext"
              name="MOTIVO DE TRASLADO"
              rules="required"
            >
              <b-form-group
                label="MOTIVO DE TRASLADO"
                label-for="reason-transfer"
              >
                <b-form-input
                  id="reason-transfer"
                  v-model="guideObject.reasonTransfer"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

        </b-row>
      </b-card>

      <b-card>
        <b-card-title>
          ITEMS
        </b-card-title>
        <b-table
          responsive="sm"
          :items="guideObject.items"
          :fields="fieldsColumns"
        >
          <template #cell(item)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.index + 1 }}
            </b-card-text>
          </template>

          <template #cell(description)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.name }}
            </b-card-text>
            <p v-html="data.item.description" />
          </template>

          <template #cell(codes)="data">
            <b-card-text class="font-weight-bold mb-25">
              <b-form-input
                v-model="data.item.codes"
                class="mb-2"
              />
            </b-card-text>
          </template>

        </b-table>
      </b-card>

      <b-card>
        <b-card-title>
          FECHA DE INICIO DE TRASLADO
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <!-- DATE -->
            <validation-provider
              #default="validationContext"
              name="FECHA"
              rules="required"
            >
              <b-form-group
                label="FECHA"
                label-for="date-transfer"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  id="date-transfer"
                  v-model="guideObject.dateTransfer"
                  class="form-control invoice-edit-input"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

        </b-row>
      </b-card>

      <!-- Form Actions -->
      <div class="d-flex mb-3">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
        >
          Guardar
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
        >
          Cancelar
        </b-button>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store'
import quotationStoreModule from '@/views/dramox/quotation/quotationStoreModule'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput, BFormInvalidFeedback,
  BFormTextarea,
  BPopover,
  BRow, VBToggle, BCardTitle, BTable, BCardText,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import InvoiceSidebarAddNewCustomer from '@/views/dramox/quotation/create/InvoiceSidebarAddNewCustomer'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import guideStoreModule from '@/views/dramox/guides/guideStoreModule'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'

export default {
  name: 'CreateGuide',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    vSelect,
    BFormInvalidFeedback,
    BCardTitle,
    BTable,
    BCardText,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-guides'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, guideStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const driverOptions = ref([])
    const vehicleOptions = ref([])
    const warehouse = ref({ })
    const guideData = ref({})
    const guideObject = ref({
      nroGuide: '',
      invoice: '',
      arrivalPoint: '',
      driverId: null,
      vehicleId: null,
      reasonTransfer: '',
      dateTransfer: null,
      items: [],
    })

    store.dispatch('app-guides/createGuide', { id: router.currentRoute.params.id })
      .then(response => {
        const {
          quotation, vehicles, drivers, warehouse: warehouseData,
        } = response.data.payload.results
        driverOptions.value = drivers
        vehicleOptions.value = vehicles
        guideData.value = quotation
        guideObject.value.items = quotation.items
        warehouse.value = warehouseData
      })

    const fieldsColumns = [
      { key: 'item', label: 'Item' },
      { key: 'description', label: 'Descripción' },
      { key: 'qty', label: 'Cantidad' },
      { key: 'codes', label: 'Códigos' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const updateItemForm = (val, type) => {

      if (type === 'vehicle') {
        const vehicle = vehicleOptions.value.find(e => e.id === val)
        guideData.value.brand = vehicle.brand
      }

      if (type === 'driver') {
        const driver = driverOptions.value.find(e => e.id === val)
        guideData.value.nroLicense = driver.nroLicense
      }
    }

    const saveGuide = () => {
      Object.assign(guideObject.value, { quotationId: guideData.value.id })


      store.dispatch('app-guides/addGuide', { body: guideObject.value })
        .then(() => {
          router.push({ name: 'guides' })
          showNotification({ title: 'Guía creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      guideObject,
      guideData,
      updateItemForm,

      driverOptions,
      vehicleOptions,
      warehouse,

      saveGuide,

      refFormObserver,
      getValidationState,
      resetForm,

      fieldsColumns,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
